import React, { PureComponent } from 'react';
import { v1 as uuidv1 } from 'uuid';
import { connect } from 'react-redux';

import isEqual from 'react-fast-compare';
import * as PropTypes from 'prop-types';
import { ViewContext } from '../context/contextIndex';

import { updateTableKeysForField, clearChildInputs } from '../../../redux/actions/actionsIndex';

import {
  makeGetTableBareKeysByField,
  makeGetTableValuesForKeyImgs,
} from '../../../redux/selectors/selectorIndex';

import {
  ReqContainer,
  TableBtn,
  BtnCol,
  HiddenTD,
  AddWrapper,
  RmWrapper,
  KeyWrapper,
  TableMain,
  TableWrapper,
  BtnText,
  Header,
  Body,
  Row,
  Cell,
  HCell,
} from '../atomicStyledComponents/stylesIndex';

import Nested from '../Nested';
import KeyTag from './KeyTag';

class Table extends PureComponent {
  constructor({
    skuIncluded,
    updateKeys,
    field,
    width = 'col-12',
    required,
    config,
    subs,
    clearRow,
    node,
    tableKeys = [],
    formContext,
    rows = 0,
    reportKey,
    pageRequired,
    disabled,
    sku,
  }) {
    const { headings } = config;

    super();
    this.skuIncluded = skuIncluded;
    this.formContext = formContext;
    this.clearRow = clearRow;
    this.updateKeys = updateKeys;
    this.required = required;
    this.field = field;
    this.node = node;
    this.width = width;
    this.headers = headings;
    this.cells = subs;
    this.tableKeys = tableKeys;
    this.rows = rows;
    this.reportKey = reportKey;
    this.pageRequired = pageRequired;
    this.disabled = disabled;
    this.sku = sku;

    this.state = {
      loaded: false,
      cells: this.cells,
      rows: this.rows,
      tableVals: {},
      tableKeys: this.tableKeys,
      rowElems: [],
    };
  }

  componentDidMount() {
    const { rows, tableKeys } = this.state;
    const { subs } = this.props;

    if (rows < 1 && this.tableKeys.length < 1) {
      const key = uuidv1();

      this.setState({
        rows: 1,
        rowElems: [this.createRow(key, subs, 0)],
      });
    }

    if (rows < 1 && tableKeys && tableKeys.length > 0) {
      this.mapTableRows(tableKeys.length);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let newState = {};

    if (nextProps.tableVals && nextProps.tableVals !== prevState.tableVals) {
      newState = { ...newState, tableVals: nextProps.tableVals };
    }

    if (!isNaN(Number(nextProps.rows)) && nextProps.rows !== prevState.rows) {
      newState = { ...newState, rows: nextProps.rows, cells: nextProps.subs };
    }

    if (Object.keys(newState).length) {
      return newState;
    }

    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    const { prenuvoID, tableVals } = this.props;
    const { rows, tableKeys } = this.state;

    if (!isEqual(prevState.tableKeys, tableKeys)) {
      this.updateKeys(this.field, tableKeys, prevState.tableKeys, prenuvoID);
    }

    if (prevState.rows !== rows || prevProps.tableVals !== tableVals) {
      this.mapTableRows(rows);
    }
  }

  componentWillUnmount() {
    const { prenuvoID } = this.props;
    this.clearRow(this.field, prenuvoID);
  }

  getUUIDtime = (uuid) => {
    const uuidArr = uuid.split('-');

    const time = [uuidArr[2].substring(1), uuidArr[1], uuidArr[0]].join('');

    return parseInt(time, 16);
  };

  mapTableRows = (rows) => {
    const { tableKeys } = this.state;
    const diff = rows - tableKeys.length;
    const loopCount = diff >= 0 ? diff : 0;

    let newKeys = [...tableKeys];

    for (let i = 0; i < loopCount; i++) {
      const key = uuidv1();
      newKeys = [...newKeys, key];
    }

    const sortedKeys = newKeys.sort((a, b) => {
      const timeA = this.getUUIDtime(a);
      const timeB = this.getUUIDtime(b);

      if (timeA < timeB) return -1;
      if (timeA > timeB) return 1;

      return 0;
    });

    const rowElems = sortedKeys.map((key, i) => this.createRow(key, this.props.subs, i));

    this.setState({ rows: rowElems.length, rowElems, tableKeys: sortedKeys });
  };

  addRow = async (e) => {
    const { tableKeys, rows } = this.state;
    const key = uuidv1();
    const addOne = rows + 1;

    const uniqueKeys = [...new Set([...tableKeys, key])];

    this.setState(
      {
        rows: addOne,
        tableKeys: uniqueKeys,
      },
      (_) => {
        this.mapTableRows(addOne);
      },
    );
  };

  removeRow = async (e) => {
    const { tableKeys, rows, rowElems } = this.state;
    const { subs, prenuvoID } = this.props;
    const { name } = e.target;

    const rowField = `${this.field}.${name}`;

    const rmOne = rows - 1;

    const filteredKeys = tableKeys.filter((key) => key !== name);

    const filteredElems = rowElems.filter((row) => row.key !== name);

    if (rows > 0 && filteredElems.length > 0) {
      this.setState({ rows: rmOne, tableKeys: filteredKeys }, async () => {
        await this.mapTableRows(rmOne);
      });
    }

    if (rows > 0 && filteredElems.length < 1) {
      const key = [uuidv1()];

      this.setState({
        rowElems: [this.createRow(key, subs, 0)],
        tableKeys: key,
      });
    }

    await this.clearRow(rowField, prenuvoID);
  };

  mapHeaders = (headers, formContext) =>
    headers.map((header, i) => (
      <HCell className="table-header" width={header.width} key={`${header.label}-header-label`}
      >
        <ReqContainer formContext={formContext}>{header.label}</ReqContainer>
      </HCell>
    ));

  createRow = (key, subs, rowIndex) => {
    const { prenuvoID, sectionTitle } = this.props;
    return (
      <Row
        key={key}
        name={key}
        className="table-row"
        width={this.width}
        gridRow={this.node === 'Multi'}
      >
        <RmWrapper>
          {this.context !== 'view' && (
            <TableBtn
              type="button"
              className="table-btn"
              onClick={this.removeRow}
              name={key}
              id={this.field}
            >
              <BtnText>-</BtnText>
            </TableBtn>
          )}
        </RmWrapper>

        {this.mapCells(subs, key, rowIndex)}

        <KeyWrapper>
          <KeyTag
            {...this.props}
            prenuvoID={prenuvoID}
            title={sectionTitle}
            parent={`${this.field}.${key}`}
            reportKey={this.reportKey}
            keyImageManifest={[]}
            isTableCell
          />
        </KeyWrapper>
      </Row>
    );
  };

  mapCells = (cells, key, rowIndex) => {
    const { prenuvoID, display } = this.props;
    return cells.slice().map((cellRef) => {
      const cell = { ...cellRef };
      if (!this.skuIncluded) {
        cell.required = false;
      }

      let disableWhen = null;
      const filters = [];
      let requireWhen = null;

      const mapWhens = (when) => {
        if (when.subs) {
          when.subs = mapWhens(when.subs);
          return when;
        }
        if (Array.isArray(when)) {
          return when.reduce((acc, i) => {
            if (i.field.includes('~')) {
              const newCondition = { ...i, field: `${this.field}.${key}${i.field.slice(1)}` };

              return [...acc, newCondition];
            }
            return [...acc, i];
          }, []);
        }
        if (when.field.includes('~')) {
          const newCondition = { ...when, field: `${this.field}.${key}${when.field.slice(1)}` };
          return newCondition;
        }
        return when;
      };
      if (cell.filters) {
        for (const filter of JSON.parse(JSON.stringify(cell.filters))) {
          const newFilter = { filter: filter.filter };
          newFilter.filterWhen = mapWhens(filter.filterWhen);
          filters.push(newFilter);
        }
      }

      if (cell.disableWhen) {
        disableWhen = mapWhens(JSON.parse(JSON.stringify(cell.disableWhen)));
      }
      if (cell.requireWhen) {
        requireWhen = mapWhens(JSON.parse(JSON.stringify(cell.requireWhen)));
      }

      return (
        <Cell
          required={cell.required}
          width={cell.width}
          className="table-cell"
          key={`${this.field}.${cell.node}.${key}`}
        >
          <Nested
            {...cell}
            field={`${this.field}.${key}.${cell.node}`}
            rowKey={key}
            parent={`${this.field}`}
            className="table-nested"
            isTableCell
            isKeyTag={this.keyTag}
            formContext={this.formContext}
            pageRequired={this.pageRequired}
            disabled={this.disabled}
            prenuvoID={prenuvoID}
            display={display}
            data={{
              ...cell,
              disableWhen,
              filters,
              requireWhen,
              field: `${this.field}.${key}.${cell.node}`,
              options: cell.options,
            }}
            sku={this.sku}
            disableWhen={disableWhen}
            filters={filters}
            requireWhen={requireWhen}
            width={null}
          />
        </Cell>
      );
    });
  };

  mapColgroup = (cells) => (
    <colgroup>
      <BtnCol className="btn-col" />

      {cells.map((_, i) => (
        <col key={`col-${i + 1}`} span="1" />
      ))}

      <BtnCol className="key-col" />
    </colgroup>
  );

  render() {
    const { subs } = this.props;
    const { rowElems } = this.state;
    return (
      <TableWrapper width={this.width}>
        <TableMain context={this.context} width={this.width}>
          {this.mapColgroup([...subs])}

          <Header width={this.width}>
            <Row width={this.width} gridRow={this.node === 'Multi'}>
              <AddWrapper>
                {this.context !== 'view' && (
                  <TableBtn
                    type="button"
                    className="table-btn"
                    context={this.context}
                    onClick={this.addRow}
                  >
                    <BtnText>+</BtnText>
                  </TableBtn>
                )}
              </AddWrapper>

              {this.mapHeaders(this.headers, this.formContext)}

              <HiddenTD />
            </Row>
          </Header>
          <Body width={this.width}>{rowElems}</Body>
        </TableMain>
      </TableWrapper>
    );
  }
}

Table.contextType = ViewContext;

const mapDispatchToProps = {
  updateKeys: updateTableKeysForField,
  clearRow: clearChildInputs,
};

const makeMapStateToProps = (_) => {
  const getTableBareKeys = makeGetTableBareKeysByField();
  const getTableValsForKeyImgs = makeGetTableValuesForKeyImgs();

  const mapStateToProps = (state, props) => {
    const tableKeys = getTableBareKeys(state, props);

    if (tableKeys.length > 0 && !isEqual(tableKeys, props.tableKeys)) {
      const newProps = { ...props, tableKeys };
      const tableVals = getTableValsForKeyImgs(state, newProps);

      if (tableVals) {
        return { tableKeys, tableVals };
      }
      return { tableKeys };
    }

    return props;
  };

  return mapStateToProps;
};

Table.propTypes = {
  skuIncluded: PropTypes.bool,
  sku: PropTypes.number,
  pageRequired: PropTypes.bool,
  formContext: PropTypes.string,
  id: PropTypes.string,
  checked: PropTypes.arrayOf(PropTypes.object),
  lastEditedBy: PropTypes.string,
  parent: PropTypes.string,
  parentID: PropTypes.string,
  disabled: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object,
  subs: PropTypes.arrayOf(PropTypes.object),
  field: PropTypes.string,
  type: PropTypes.string,
  width: PropTypes.string,
  show: PropTypes.string,
  className: PropTypes.string,
  isTableCell: PropTypes.bool,
  prenuvoID: PropTypes.string,
  required: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  config: PropTypes.object,
  reportKey: PropTypes.arrayOf(PropTypes.object),
  // eslint-disable-next-line react/forbid-prop-types
  showWhen: PropTypes.object,
  node: PropTypes.string,
  nested: PropTypes.bool,
  display: PropTypes.bool,
  tableKeys: PropTypes.arrayOf(PropTypes.string),
  // eslint-disable-next-line react/forbid-prop-types
  tableVals: PropTypes.object,
  updateKeys: PropTypes.func,
  clearRow: PropTypes.func,
};

Table.defaultProps = {
  skuIncluded: true,
  sku: 300,
  pageRequired: true,
  formContext: 'reading',
  id: '',
  checked: [],
  lastEditedBy: '',
  parent: '',
  parentID: '',
  disabled: false,
  data: {},
  subs: [],
  field: '',
  type: '',
  width: 'col-12',
  show: 'always',
  className: '',
  isTableCell: false,
  prenuvoID: '',
  required: true,
  config: {},
  reportKey: [],
  showWhen: null,
  node: '',
  nested: false,
  display: true,
  tableKeys: [],
  tableVals: {},
  updateKeys: null,
  clearRow: null,
};

export default connect(makeMapStateToProps, mapDispatchToProps, null, {
  pure: true,
  areStatePropsEqual(next, prev) {
    return isEqual(next, prev);
  },
})(Table);
